/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase from 'firebase/compat/app'
import Image from 'next/image'
import useLogger from 'src/context/LoggerProvider/useLogger'
import { IRegisterUserInput } from 'src/types/graphql'
import ga from 'src/utils/gtag'
import { Colors, FontSize } from '../../constants/theme'
import BaseButton from '../Buttons/BaseButton'
import { LoginTypeEvent } from './common'

type IProps = {
  saveUserInfo: (
    variables: IRegisterUserInput,
    type: LoginTypeEvent
  ) => Promise<void>
}

export default function SocialLogin({ saveUserInfo }: IProps) {
  const logger = useLogger()

  const onFbClick = () => {
    const fbProvider = new firebase.auth.FacebookAuthProvider()
    fbProvider.setCustomParameters({ display: 'popup' })
    firebase
      .auth()
      .signInWithPopup(fbProvider)
      .then(async ({ additionalUserInfo }) => {
        if (additionalUserInfo?.isNewUser) {
          const { family_name, given_name } =
            (additionalUserInfo.profile as any) ?? {}
          await saveUserInfo(
            {
              firstname: given_name,
              lastname: family_name
            },
            'facebook'
          )
        } else {
          ga.event('login')
        }
      })
      .catch((error) => {
        logger.error('Error loggin in with facebook', {}, error)
      })
  }

  const onGClick = () => {
    const gProvider = new firebase.auth.GoogleAuthProvider()
    gProvider.setCustomParameters({ display: 'popup' })
    firebase
      .auth()
      .signInWithPopup(gProvider)
      .then(async ({ additionalUserInfo }) => {
        if (additionalUserInfo?.isNewUser) {
          const { email, family_name, given_name, picture } =
            (additionalUserInfo.profile as any) ?? {}
          await saveUserInfo(
            {
              email,
              firstname: given_name,
              lastname: family_name,
              profilePicture: { id: 'google', url: picture, name: 'google' }
            },
            'google'
          )
        } else {
          ga.event('login')
        }
      })
      .catch((error) => {
        logger.error('Error loggin in with google', {}, error)
      })
  }

  return (
    <>
      <BaseButton
        fullWidth
        variant="outlined"
        rounded
        size="large"
        fontSize={FontSize.small}
        customColor={Colors.black300}
        customContrastColor={Colors.black400}
        sx={{ mb: 1.5, gap: 1 }}
        onClick={onGClick}
      >
        <Image
          src="/svgs/google-icon.svg"
          alt="google icon"
          width={20}
          height={20}
          priority
        />
        Continuar con Google
      </BaseButton>

      <BaseButton
        fullWidth
        variant="outlined"
        rounded
        size="large"
        fontSize={FontSize.small}
        customColor={Colors.black300}
        customContrastColor={Colors.black400}
        sx={{ mb: 1.5, gap: 1 }}
        onClick={onFbClick}
      >
        <Image
          src="/svgs/facebook-icon.svg"
          alt="google icon"
          width={20}
          height={20}
          priority
        />
        Continuar con Facebook
      </BaseButton>
    </>
  )
}
