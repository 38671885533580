import {
  CheckoutType,
  ICheckout,
  InputMaybe,
  IPhoto,
  IUser,
  Maybe
} from '../types/graphql'
import {
  CheckoutView,
  IFilterCanStoreVehicle,
  IPrivatePhotoInput,
  RedirectUrls
} from '../types/types'

const DecimalTwoDigitsRegularExpression =
  /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/

const TwoDecimalRegularExpression = /^\d+(\.\d{1,2})?$/

function convertRangeToZoom(range: number): number {
  let zoom = Math.round(Math.log(35200000 / range) / Math.log(2))
  if (zoom < 0) zoom = 0
  else if (zoom > 19) zoom = 19
  return zoom
}

function convertZoomToRange(zoom: number): number {
  let range = 35200000 / Math.pow(2, zoom)
  if (range < 300) range = 300
  return range
}

const convertZoomToRangeString = (value: number): string => {
  const range = convertZoomToRange(value)
  if (range > 1000) {
    return (range / 1000).toFixed(0) + 'km'
  }
  return range.toFixed(0) + 'm'
}

function hasBeenTwoDays(date: string): boolean {
  const date1 = new Date(date)
  const date2 = new Date()
  const hours = Math.abs(date1.getTime() - date2.getTime()) / 36e5
  return hours > 48
}

function hoursRemainingCheckout({ completedAt }: ICheckout): string {
  const now = new Date()
  const target = new Date(completedAt as string)
  const expiration = new Date(target.setDate(target.getDate() + 2))
  const hours = Math.abs(now.getTime() - expiration.getTime()) / 36e5
  return hours.toFixed(0)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStepIndex = (stepName: string, flow: any[]) => {
  return flow.findIndex((item) => item.id === stepName)
}

function validEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

function validPassword(password: string): boolean {
  const re = new RegExp(
    '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
  )
  return re.test(password)
}

function convertPhotoInput(photo: IPhoto): IPrivatePhotoInput {
  return {
    id: photo.id ?? '',
    url: photo.url ?? '',
    name: photo.name ?? '',
    width: photo.width,
    height: photo.height
  }
}

export function formatDateSimple(d: string | undefined | null): string {
  const date = new Date(d ?? new Date())
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear()
  return year + '/' + month + '/' + day
}

function formatDatestamp(date: string | undefined | null): string {
  const lang = 'es-US'
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }

  return !date
    ? new Date().toLocaleString(lang, options)
    : new Date(date).toLocaleString(lang, options)
}

function formatTimestamp(date: string | undefined | null): string {
  const lang = 'es-US'
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Mexico_City'
  }

  return !date
    ? new Date().toLocaleString(lang, options)
    : new Date(date).toLocaleString(lang, options)
}

function formatTimestampTime(date: string | undefined | null): string {
  const lang = 'es-US'
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Mexico_City'
  }

  return !date
    ? new Date().toLocaleString(lang, options)
    : new Date(date).toLocaleString(lang, options)
}

function formatTimestampMonthDay(
  date: string | undefined | null,
  opt?: Intl.DateTimeFormatOptions
): string {
  const lang = 'es-US'
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    timeZone: 'America/Mexico_City',
    ...opt
  }

  return !date
    ? new Date().toLocaleString(lang, options)
    : new Date(date).toLocaleString(lang, options)
}

function formatTimestampMonthDayYear(date: string | undefined | null): string {
  const lang = 'es-US'
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'America/Mexico_City'
  }

  return !date
    ? new Date().toLocaleString(lang, options)
    : new Date(date).toLocaleString(lang, options)
}

function getYearDate(date: string | undefined | null): string {
  if (!date) {
    return new Date().toLocaleDateString('en-us', {
      year: 'numeric'
    })
  }

  return new Date(date).toLocaleDateString('en-us', {
    year: 'numeric'
  })
}

function numberWithCommas(
  num: number | string | undefined | null
): string | undefined {
  const parsed = typeof num === 'string' ? parseFloat(num) : num
  const res = parsed?.toLocaleString('es-US')
  return res
}

function numberWithCommasAndDecimal(
  x: Maybe<number> | undefined
): string | undefined {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
  const res = x?.toLocaleString('es-US', options)
  return res
}

function numberRounedUpWithoutDecimals(
  x: Maybe<number> | undefined
): string | undefined {
  const round = Math.round(x ?? 0) as number
  const res = round.toLocaleString('es-US')

  return res
}

function addressSplit(address: Maybe<string> | undefined): {
  country: string
  state: string
  city?: string
} {
  const split = address?.split(',')

  if (!split || split.length <= 0)
    return {
      country: '',
      state: ''
    }

  const length = split.length
  try {
    const country = split[length - 1].trim()
    const state = split[length - 2].replace(/[0-9]/g, '').trim()

    if (length <= 3) {
      return { state, country }
    } else {
      const city = split[length - 3].replace(/[0-9]/g, '').trim()
      return { state, country, city }
    }
  } catch (_) {
    return {
      country: '',
      state: ''
    }
  }
}

function displayAddress(address: Maybe<string> | undefined): string {
  if (!address) return ''
  const split = address?.split(',')

  if (!split || split.length <= 0) return ''

  const length = split.length
  try {
    const country = split[length - 1]
    const state = split[length - 2].replace(/[0-9]/g, '').trim()

    if (length < 3) {
      return `${state}, ${country}`
    } else {
      const city = split[length - 3].replace(/[0-9]/g, '').trim()
      return `${city}, ${state}`
    }
  } catch (_) {
    return ''
  }
}

const externalImageLoader = ({ src }: { src: string }) => src

const getSubmitLocationUrl = (isAuthenticated: boolean) =>
  isAuthenticated
    ? RedirectUrls.Listings
    : `${RedirectUrls.Login}?redirect=${RedirectUrls.Listings}`

const getCheckoutTabAndView = (
  currentUser: Maybe<IUser>,
  checkout: ICheckout
): {
  checkoutTab: CheckoutType
  checkoutView: CheckoutView
  dashboardPage: RedirectUrls
} => {
  const checkoutView =
    currentUser?.id === checkout.renterId
      ? CheckoutView.Rent
      : CheckoutView.Reservation
  const checkoutTab = checkout.hasEnded
    ? CheckoutType.Past
    : checkout.landlordAssessedAt && !checkout.isLandlordApproved
      ? CheckoutType.Past
      : checkout.startedAt && checkout.isLandlordApproved
        ? CheckoutType.Active
        : checkout.landlordAssessedAt && checkout.isLandlordApproved
          ? CheckoutType.UpComing
          : checkout.completedAt && !hasBeenTwoDays(checkout.completedAt)
            ? CheckoutType.Pending
            : CheckoutType.Past
  const dashboardPage =
    checkoutView === CheckoutView.Rent
      ? RedirectUrls.Reservations
      : RedirectUrls.Rents

  return { checkoutTab, checkoutView, dashboardPage }
}

async function fetchWithTimeout(
  resource: RequestInfo,
  options?: RequestInit & { timeout?: number }
): Promise<Response> {
  const { timeout = 8000 } = options ?? {}
  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  })
  clearTimeout(id)
  return response
}

function getUserName(user: IUser): string {
  return `${
    user.firstname ?? user.userChanges?.firstname ?? 'Usuario Anonimo'
  } ${user.lastname ?? user.userChanges?.lastname ?? ''}`
}

function mapCanStoreVehicleToRadio(
  value: InputMaybe<boolean> | undefined
): IFilterCanStoreVehicle {
  if (value === true) return IFilterCanStoreVehicle.Vehicle
  if (value === false) return IFilterCanStoreVehicle.SelfStorage
  return IFilterCanStoreVehicle.Both
}

function mapRadioToCanStoreVehicle(
  value: IFilterCanStoreVehicle
): boolean | undefined {
  switch (value) {
    case IFilterCanStoreVehicle.Vehicle:
      return true
    case IFilterCanStoreVehicle.SelfStorage:
      return false
    case IFilterCanStoreVehicle.Both:
    default:
      return undefined
  }
}

function isANumber(value: string) {
  return !isNaN(parseFloat(value))
}

function isNonNegative(num: number): boolean {
  return num >= 0
}

function dateIsValid(date: Date) {
  return !Number.isNaN(new Date(date).getTime())
}

function getStepProgressBarPecentage(stepArray: string[], step: string) {
  return Number(
    ((stepArray.indexOf(step) / (stepArray.length - 1)) * 100).toFixed(2)
  )
}

export {
  addressSplit,
  convertPhotoInput,
  convertRangeToZoom,
  convertZoomToRange,
  convertZoomToRangeString,
  dateIsValid,
  DecimalTwoDigitsRegularExpression,
  displayAddress,
  externalImageLoader,
  fetchWithTimeout,
  formatDatestamp,
  formatTimestamp,
  formatTimestampMonthDay,
  formatTimestampMonthDayYear,
  formatTimestampTime,
  getCheckoutTabAndView,
  getStepProgressBarPecentage,
  getSubmitLocationUrl,
  getUserName,
  getYearDate,
  hasBeenTwoDays,
  hoursRemainingCheckout,
  isANumber,
  isNonNegative,
  mapCanStoreVehicleToRadio,
  mapRadioToCanStoreVehicle,
  numberRounedUpWithoutDecimals,
  numberWithCommas,
  numberWithCommasAndDecimal,
  TwoDecimalRegularExpression,
  validEmail,
  validPassword
}
