import Typography from '@mui/material/Typography'
import firebase from 'firebase/compat/app'
import Image from 'next/image'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useAuthProvider } from 'src/context/AuthProvider'
import useLogger from 'src/context/LoggerProvider/useLogger'
import { AuthEmailType } from 'src/types/graphql'
import { RedirectUrls } from 'src/types/types'
import ga from 'src/utils/gtag'
import { Colors, FontSize, FontWeight, Spacing } from '../../../constants/theme'
import BaseButton from '../../Buttons/BaseButton'
import BaseIconButton from '../../Buttons/BaseIconButton'
import FlexBox from '../../FlexBox'
import PasswordInput from '../PasswordInput'
import { IEmailState } from '../common'
import { StyledContainer } from '../styled'
import ForgotPassword from './ForgotPassword'

type IProps = {
  email: string
  setEmailState: (state: IEmailState) => void
}

export default function ContinueEmail({ email, setEmailState }: IProps) {
  const logger = useLogger()

  const [hasError, setHasError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [forgotPasswordScreen, setForgotPasswordScreen] =
    useState<boolean>(false)

  const { sendAuthEmail } = useAuthProvider()
  const { enqueueSnackbar } = useSnackbar()

  const handleOnLogin = () => {
    if (!password) {
      setHasError(true)
      return
    }
    setLoading(true)
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        if (!user?.emailVerified) {
          await sendAuthEmail(
            email,
            AuthEmailType.Verification,
            RedirectUrls.Home
          )
        }
        setHasError(false)
        ga.event('login')
      })
      .catch((error) => {
        if (error.code === 'auth/wrong-password') {
          setHasError(true)
        } else {
          logger.error('Error email/password login', {}, error)
          enqueueSnackbar('¡Hubo un error! Por favor intenta de nuevo.', {
            variant: 'error'
          })
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  if (forgotPasswordScreen) {
    return (
      <ForgotPassword
        setForgotPasswordScreen={setForgotPasswordScreen}
        initialEmail={email}
      />
    )
  }

  return (
    <StyledContainer>
      <FlexBox alignCenter gap={1}>
        <BaseIconButton onClick={() => setEmailState({})}>
          <Image
            src="/svgs/back-arrow.svg"
            alt="back-arrow"
            width={15}
            height={15}
            quality={100}
          />
        </BaseIconButton>
        <Typography fontWeight={FontWeight.bold} color={Colors.black500}>
          Ingresa tu contraseña
        </Typography>
      </FlexBox>

      <FlexBox fullWidth direction="column" pt={Spacing.medium}>
        <PasswordInput
          password={password}
          setPassword={setPassword}
          hideTooltip
          hasError={hasError}
        />
      </FlexBox>

      <Typography
        component="span"
        fontSize={FontSize.small}
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        fontWeight={FontWeight.medium}
        color={Colors.black400}
        onClick={() => setForgotPasswordScreen(true)}
        mx={{ mt: 0.5 }}
      >
        ¿Olvidaste tu contraseña?
      </Typography>

      <BaseButton
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        onClick={handleOnLogin}
        rounded
        loading={loading}
        sx={{ mt: 2 }}
      >
        Iniciar sesión
      </BaseButton>
    </StyledContainer>
  )
}
